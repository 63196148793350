import MainLayout from "../template/MainLayout"
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      var content = {
            url: 'https://melarix.com/privacy',
            title: 'Privacy Policy - How We Protect and Use Your Information',
            description: 'Read our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about our data practices, cookies, and your rights.',
            type: 'website',
            image: 'https://melarix.com/melarix-mascut.png'


      }
      return (
            <MainLayout> 
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />
                        <link rel="canonical" href={content.url.toString()} />


                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <>
                        <div className="page-body">
                              <div className="page-head">
                                    <h1>Privacy Policy</h1>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>1. Information Collection </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      1.1. We collect personal information when you register, use the platform, or communicate with us.
                                                </p><p>
                                                      1.2. We may collect data such as your name, email address, and usage data.
                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>2. Use of Information</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      2.1. We use your information to provide and improve the platform's services.
                                                </p><p>
                                                      2.2. We may use your information to send you updates and promotional materials.
                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>3. Data Sharing </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      We do not share your personal information with third parties, except as required by law.
                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>4. Cookies and Analytics </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      We may use cookies and analytics tools to collect information about your usage of the platform.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>5. Security </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      We take reasonable measures to protect your information, but we cannot guarantee its absolute security.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>6. Changes to Privacy Policy </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      We may update this Privacy Policy. Continued use of the platform after changes constitutes acceptance of the new policy.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 7. Contact Information</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@melarix.com">contact@melarix.com</a>.
                                                </p>
                                          </div>
                                    </div>
                              </div>





                        </div>
                  </>
            </MainLayout>
      )
}

export default PrivacyPolicy