import HomeBigTitle from "./HomeBigTitle"
import CourseTable from "./courseTable"
import MainLayout from "../template/MainLayout"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { Helmet } from "react-helmet"



function CourseHome() {
      console.log('courseHome start')
      const [myState, setMyState] = useState('initialState')
      const location = useLocation();

      const { ref } = useParams();

      useEffect(() => {
            //console.log('in use effect - ref: ' + ref)
            recordVisit('/home', ref)

      }, [myState])
      // if (ref != undefined) {
      //       useEffect(() => {
      //             recordVisit('/home', ref)
      //       }, [myState])
      // } else {
      //       useEffect(() => {
      //             recordVisit('/home')
      //       }, [myState])
      // }

      let schema = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "melarix.com",
            "url": "https://melarix.com",
            "alternateName": "melarix.com",
            "description": "Your go-to platform for mastering blockchain development. Explore courses, tutorials, and resources to level up your skills. Join us today!",
            "specialty": "Blockchain",

            "relatedLink": "https://melarix.com/about"
            // "about": "We're here to break the barriers of traditional education, providing you with essential skills to craft your own path in blockchain-based development. In these early stages, Melarix is diving deep into dApps and web3 development. As we grow, we'll expand our horizons to cover a variety of exciting topics. Join us on this journey, and let's redefine online learning together. Melarix is more than a platform; it's your key to unlocking the vast potential of blockchain development. Get ready to learn, create, and innovate with us!"
      }

      var pageContent = {
            url: 'https://melarix.com/',
            title: 'Melarix - Master Blockchain Development | Courses & Tutorials',
            description: 'Your go-to platform for mastering blockchain development. Explore courses, tutorials, and resources to level up your skills. Join us today!',
            type: 'website',
            image: 'https://melarix.com/melarix-mascut.png'


      }
      console.log( JSON.stringify(schema) )

      return (
            <MainLayout>
                  <Helmet>
                        <script type="application/ld+json">
                              {JSON.stringify(schema)}
                        </script>
                        
                        <title>{pageContent.title.toString()}</title>
                        <meta name="title" content={pageContent.title.toString()} />
                        <meta name="description" content={pageContent.description.toString()} />
                        <link rel="canonical" href={pageContent.url.toString()} />

                        {"<!-- Open Graph Meta Tags -->"}
                        <meta property="og:url" content={pageContent.url.toString()} />
                        <meta property="og:type" content={pageContent.type.toString()} />
                        <meta property="og:title" content={pageContent.title.toString()} />
                        <meta property="og:description" content={pageContent.description.toString()} />
                        <meta property="og:image" content={pageContent.image.toString()} />
 
                  </Helmet>
                  <HomeBigTitle />
                  <CourseTable />
            </MainLayout>
      )
}
export default CourseHome