import CourseHome from './component/pages/coursePage/courseHome';
import './asset/style/App.css';
import './asset/style/card.css';
import './asset/style/font.css';
import './asset/style/guide.css';
import './asset/style/headerfooter.css';
import './asset/style/index.css';
import './asset/style/style.css';
import './asset/style/course.css';
import './asset/style/article.css';
import './asset/style/page.css';
import './asset/style/responsive.css';




// simple routing for version 2
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import About from './component/pages/sidePages/about';
import Course from './component/pages/coursePage/singleCoursePage/course'
import ArticleList from './component/pages/articlePage/Articles';
import Article from './component/pages/articlePage/Article';
import Video from './component/pages/video/videoPlay'
import LightPaper from './component/pages/sidePages/lightPaper';
import TermsOfUse from './component/pages/sidePages/termsOfUse';
import PrivacyPolicy from './component/pages/sidePages/privacyPolicy';
// import Router from "./routing"


import { getGeneralCookie } from './asset/js/site-cookies'
// import { useEffect } from "react"



function App() {
  //getGeneralCookie()
  return (
    <div className="App">
      <BrowserRouter  >
        <Routes>
          {/* Starting from homepage */}
          <Route path='/:ref' element={<CourseHome />} />


          {/* Courses */}
          <Route path='/course/:id' element={<Course />} />
          <Route path='/video/:id' element={<Video />} />
          {/* <Route path='/video/:course/:id' element={<Video />} /> */}
          <Route path='/course/:courseTitle/videos/:videoTitle' element={<Video />} />


          {/* Articles */} 
          <Route exact path="/article/:id" element={<Article />} />
          <Route exact path="/article/:id" element={<Article />} />
          <Route path='/articles' element={<ArticleList />} />
          <Route path='/article' element={<ArticleList />} />


          {/* Side pages */}
          <Route path='/about' element={<About />} />
          <Route path='/lightpaper' element={<LightPaper />} />
          <Route path='/terms_of_use' element={<TermsOfUse />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />


          {/* Affiliate pages */}
          {/* // go to affiliate home */}
          {/* <Route path='/affiliate' element={<About />} /> */}



          {/* Not found - 404 */}
          <Route path='*' element={<CourseHome />} />


        </Routes>
      </BrowserRouter  >

    </div>
  );
}




// function App2() {
//   getGeneralCookie()


//   return (
//     <>
//       <RouterProvider router={Router} />
//     </>

//   );
// }

// function App3() {
//   return (
//     <div className="App">
//       <Router />
//     </div>)
// }
export default App;
