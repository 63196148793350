
function ConnectWalletButton() {
      return (
            <>

                  {/* <div className="header-btns">
                        <div className="connect-btn">
                              Connect Wallet
                        </div>
                  </div> */}
            </>
      );
}

export default ConnectWalletButton;